<template>
  <div>
      <div class="w-full flex space-x-1">
        <div class="w-1/2 ">
          <img :src="require('@/assets/landing1.jpg')">
        </div>
        <div class="w-1/2">
          <img :src="require('@/assets/landing2.jpg')">
        </div>
      </div>
      <div class="mt-16">
<h1 class="font-bold">WILLKOMMEN BEI GRONEBERG SCHWESTERN FRISEUR IN HAMBURG | EIMSBÜTTEL</h1><br />
<div class="hidden 2xl:block">
Wir glauben an Kreativität. Wir sehen, wer Du bist und hören zu, wer Du sein möchtest. Darauf aufbauend kreieren wir Deinen Look.<br />Wir sind ein starkes Team in ständiger Entwicklung,
das auf ein und dasselbe Ziel hinarbeitet: Dir ein persönliches ganzheitliches Erlebnis zu bieten!<br />Wir nehmen uns Zeit für unsere Kunden, bieten gründliche Beratung und
maßgeschneiderte Behandlungen an. Zu uns zu kommen sollte sich gleichzeitig entspannend und familiär anfühlen.<br /><br />
Egal wer du bist oder welchen Stil du willst, du bist bei GS immer willkommen!
</div>
<div class="2xl:hidden">
Wir glauben an Kreativität. Wir sehen, wer Du bist und hören zu, wer Du sein möchtest. Darauf aufbauend kreieren wir Deinen Look. Wir sind ein starkes Team in ständiger Entwicklung,
das auf ein und dasselbe Ziel hinarbeitet: Dir ein persönliches ganzheitliches Erlebnis zu bieten! Wir nehmen uns Zeit für unsere Kunden, bieten gründliche Beratung und
maßgeschneiderte Behandlungen an. Zu uns zu kommen sollte sich gleichzeitig entspannend und familiär anfühlen.<br /><br />
Egal wer du bist oder welchen Stil du willst, du bist bei <span class="font-bold">GS</span> immer willkommen!
</div>
      </div>

  </div>
</template>

<script>
export default {
metaInfo() {
        return { 
            title: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81",
            meta: [
                { name: 'description', content:  'Wir glauben an Kreativität. Wir sehen, wer Du bist und hören zu, wer Du sein möchtest. Deine Zufriedenheit ist unser Ziel. Finde uns in der Osterstr. 166 in Eimsbüttel.'},
                { property: 'og:title', content: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81"},
                { property: 'og:site_name', content: 'Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

<style>

</style>