import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/tailwind.css'
import VueScrollactive from 'vue-scrollactive';
import AOS from 'aos'
import 'aos/dist/aos.css'
//import axios from 'axios'
//import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueScrollactive);
//Vue.use(VueAxios, axios)
Vue.use(Meta)

Vue.config.productionTip = false



import Landing from './components/Landing.vue'
import Team from './components/Team.vue'
import Jobs from './components/Jobs.vue'
import Impressum from './components/Impressum.vue'
import Salon from './components/Salon.vue'
import Buchung from './components/Buchung.vue'
import Kontakt from './components/Kontakt.vue'
import Preisliste from './components/Preisliste.vue'

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: Jobs
    },
    {
      path: '/impressum',
      name: 'impressum',
      component: Impressum
    },
    {
      path: '/buchung',
      name: 'buchung',
      component: Buchung
    },
    {
      path: '/salon',
      name: 'salon',
      component: Salon
    },
    {
      path: '/kontakt',
      name: 'kontakt',
      component: Kontakt
    },
    {
      path: '/preisliste',
      name: 'preisliste',
      component: Preisliste
    },
    {
     path: '/team',
      name: 'team',
      component: Team
    }/*,
    {
     path: '/about/more',
      name: 'about-more',
      component: AboutMore,
      meta: { transitionName: 'slide' }
    }*/
  ],
  mode: 'history'
});
/*
function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

getRoutesList(router.options.routes, 'https://www.groneberg-schwestern.de');

function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://www.groneberg-schwestern.de')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

console.log(getRoutesXML());
*/
new Vue({
  created () {
    AOS.init()
  },
  render: h => h(App),
  router
}).$mount('#app')
