<template>
  <div>
    <div class="headline uppercase underline font-bold tracking-wider mb-8 text-xl">
        PREISLISTE
    </div>
    <div class="preisliste">
     <div class="grid grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-8">
        <div>
            <div class="underline font-bold text-left">
                DAMEN
            </div>
            <div class="">
                <div class="grid grid-cols-4 text-left leading-8">
                    <template v-for="d in preise.damen">
                        <div class="col-span-3" :key="d.name">{{ d.name }}</div>
                        <div class="text-right" :key="d.name">{{ d.preis }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div>
            <div class="underline font-bold text-left mt-16 md:mt-0">
                KOSMETIK
            </div>
            <div class="text-left leading-8">
               <div class="grid grid-cols-4 text-left leading-8">
                    <template v-for="d in preise.kosmetik">
                        <div class="col-span-3" :key="d.name">{{ d.name }}</div>
                        <div class="text-right" :key="d.name">{{ d.preis }}</div>
                    </template>
                </div>
            </div>
            <!--<div class="underline font-bold text-left mt-16 md:mt-8">
                BRAUTSERVICE
            </div>
            <div class="text-left leading-8">
               <div class="grid grid-cols-4 text-left leading-8">
                    <div class="col-span-3">Probetermin Frisur</div>
                    <div class="text-right">50 €</div>
                    <div class="col-span-3">Probetermin Make-up</div>
                    <div class="text-right">45 €</div>
                    <div class="col-span-3">Brautfrisur am Tag der Hochzeit</div>
                    <div class="text-right">70 €</div>
                    <div class="col-span-3">Make-up am Tag der Hochzeit</div>
                    <div class="text-right">55 €</div>
                    <div class="col-span-3">Unser Brautpaket<br /></div>
                    <div class="text-right">200 €</div>
                    <div class="col-span-4 leading-none -mt-2"><span class="" style="font-size:8pt">Beinhaltet den kompletten Brautservice. Sie sparen 25 €.</span></div>
                </div>
            </div>-->
        </div>
        <div>
            <div class="underline font-bold text-left mt-16 md:mt-0">
                HERREN
            </div>
            <div class="text-left">
               <div class="grid grid-cols-4 text-left leading-8">
                    <template v-for="d in preise.herren">
                        <div class="col-span-3" :key="d.name">{{ d.name }}</div>
                        <div class="text-right" :key="d.name">{{ d.preis }}</div>
                    </template>
                </div>
            </div>
            <div class=" text-left mt-16 md:mt-8">
                <span class="underline font-bold mr-2">KINDER</span> <span style="font-size:9pt">bis 8 Jahre</span>
            </div> 
            <div class="text-left leading-8">
               <div class="grid grid-cols-4 text-left leading-8">
                    <template v-for="d in preise.kinder">
                        <div class="col-span-3" :key="d.name">{{ d.name }}</div>
                        <div class="text-right" :key="d.name">{{ d.preis }}</div>
                    </template>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="block w-full border-solid border-t-2 border-thegray my-32">
        </div>
    <div class="headline uppercase underline font-bold tracking-wider mb-8 text-xl">
        LEISTUNGEN
    </div>
    <div>
        <span class="font-bold underline">SCHNITT</span><br /><br />

Neben der Scherentechnik beherrschen unsere Mitarbeiter auch die Razorklinge<br /><br /><br />



<span class="font-bold underline">FARBE</span><br /><br />

Wir arbeiten mit Schwarzkopf Professional Produkten<br /><br /><br />




<span class="font-bold underline">STRÄHNEN</span><br /><br />

Neben Folientechnik, auch mit dem Color-/ Effektboard. Besonders umweltschonend, da keine Folie verwendet wird<br /><br /><br />




<span class="font-bold underline">TÖNUNG</span><br /><br />

Eine Haartönung ist die schonende Alternative zur herkömmlichen Coloration<br /><br /><br />




<span class="font-bold underline">KERATINBEHANDLUNG</span><br /><br />

Sorgt für eine "geballte" Anreicherung von natürlichem Keratin im Inneren der Haarstruktur<br /><br /><br />




<span class="font-bold underline">STYLING</span><br /><br />

Unser Team wurde speziell für Styling aller Arten geschult<br /><br /><br />




<span class="font-bold underline">HOCHSTECK-, FLECHT- UND BRAUTFRISUREN</span><br /><br />

Nicht nur für besondere Anlässe sondern auch für den Alltag<br /><br /><br />




<span class="font-bold underline">PROFESSIONELLES MAKE-UP</span><br /><br />

Individuell auf Ihren Typ abgestimmt<br /><br /><br />




<span class="font-bold underline">KOSMETIK</span><br /><br />

Wimpernfarbe, Augen- brauenfarbe, Augenbrauen zupfen (Pinzette & Faden)
    </div>
  </div>
</template>

<script>
export default {
    data: function() {
        return {
            preise: { 
                damen: [
                    { name: "Waschen/Schnitt/Selberföhnen", preis: "39 €" },
                    { name: "Schnitt, trocken", preis: "32 €" },
                    { name: "Typveränderung inkl. Fönen und Styling", preis: "69 €" },
                    { name: "Waschen/Schnitt/Föhnen", preis: "ab 50 €" },
                    { name: "Waschen/Föhnen", preis: "ab 33 €" },
                    { name: "Waschen/Glätten", preis: "ab 35 €" },
                    { name: "Farbe/Tönung", preis: "ab 50 €" },
                    { name: "Blondierung mit Fibre Plex", preis: "ab 55 €" },
                    { name: "Strähnen Folie Oberkopf", preis: "ab 55 €" },
                    { name: "Strähnen Folie ganzer Kopf", preis: "ab 90 €" },
                    { name: "Strähnen Effektboard/freihand", preis: "ab 45 €" },
                    { name: "Painting hochsetzen/auffrischen", preis: "ab 80 €" },
                    { name: "Painting komplett", preis: "ab 160 €" },
                    { name: "Intensivpflege", preis: "ab 11 €" },
                ],
                kosmetik: [
                    { name: "Wimpern färben", preis: "16 €" },
                    { name: "Augenbrauen färben", preis: "11 €" },
                    { name: "Augenbrauen zupfen", preis: "ab 8 €" },
                    { name: "Schöne-Augen-Paket", preis: "30 €" },
                ],
                herren: [
                    { name: "Schnitt, trocken", preis: "30 €" },
                    { name: "Schnitt, nass", preis: "33 €" },
                    { name: "Typveränderung inkl Styling", preis: "55 €" },
                ],
                kinder: [
                    { name: "Je nach Zeitaufwand", preis: "15-25 €" },
                ]
            },
        }
    },
metaInfo() {
        return { 
            title: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Preisliste & Leistungen",
            meta: [
                { name: 'description', content:  'Finde hier unsere Preise und was wir Dir dafür anbieten.'},
                { property: 'og:title', content: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Preisliste & Leistungen"},
                { property: 'og:site_name', content: 'Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Preisliste & Leistungen'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

<style>

</style>