<template>
  <div>
    <div class="headline uppercase underline font-bold tracking-wider mb-8 text-xl">
        KONTAKT
    </div>
    <div class="kontakt">
     <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-12">
        <div>
            <div class="underline font-bold mb-8 text-left">
                ÖFFNUNGSZEITEN
            </div>
            <div class="">
                <div class="grid grid-cols-2 text-left leading-8">
                    <div class="">Montag</div>
                    <div class="">geschlossen</div>
                    <div class="">Dienstag</div>
                    <div class="">08:30-19:00</div>
                    <div class="">Mittwoch</div>
                    <div class="">08:30-19:00</div>
                    <div class="">Donnerstag</div>
                    <div class="">08:30-19:00</div>
                    <div class="">Freitag</div>
                    <div class="">08:30-19:00</div>
                    <div class="">Samstag</div>
                    <div class="">09:00-15:00</div>
                </div>
            </div>
        </div>
        <div>
            <div class="underline font-bold mb-8 text-left mt-16 md:mt-0">
                ADRESSE
            </div>
            <div class="text-left leading-8">
                <span class="font-bold">
                    GRONEBERG SCHWESTERN FRISEUR
                </span><br />
                Osterstr. 166<br />
                20255 Hamburg | Eimsbüttel<br />
                +49 40 38633581<br />
                <div class="mt-16"><a href="https://www.google.com/maps/place/Groneberg+Schwestern+Friseur/@53.5786991,9.9447612,17z/data=!3m1!4b1!4m5!3m4!1s0x47b185ff32a0f91b:0x6cec227fd30a0b4d!8m2!3d53.5786991!4d9.9469552" target="_blank"><img :src="require('@/assets/map.jpg')" /></a></div>
            </div>
        </div>
        <div>
            <div class="underline font-bold mb-8 text-left mt-16 md:mt-0">
                WICHTIGE HINWEISE
            </div>
            <div class="text-left">
                Terminanfragen für Farben werden ausschließlich über das Telefon angenommen.<br /><br />
                Euer Termin ist ausschließlich für euch gebucht. Solltet ihr euren Termin nicht 24 Stunden vorher absagen, müssen wir leider 50% des Preises berechnen.<br /><br />
                Achtet bitte auf die ausgehangenen Corona-Richtlinien.
            </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
metaInfo() {
        return { 
            title: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Kontakt & Zeiten",
            meta: [
                { name: 'description', content:  'Du findest uns in der Osterstr. 166 in Hamburg Eimsbüttel. Wir sehen uns. :)'},
                { property: 'og:title', content: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Kontakt & Zeiten"},
                { property: 'og:site_name', content: 'Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Kontakt & Zeiten'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

<style>

</style>