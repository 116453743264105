<template>
	<div>
		<div
			class="headline uppercase underline font-bold tracking-wider mb-8 text-xl"
		>
			TEAM
		</div>
		<div class="mitarbeiter">
			<div class="grid grid-cols-1 gap-2 md:grid-cols-1 md:gap-12">
				<div>
					<img :src="require('@/assets/mitarbeiter/nadine.jpg')" />
					Nadine, Inhaberin
					<br />
					<br />
					Gebürtig komme ich von der schönen Halbinsel Fischland-Darß-Zingst.
					<br />
					Im Sommer 1999 zog es mich zur Ausbildung nach Hamburg und diese
					beendete ich im Februar 2002.
					<br />
					Nach dem jahrelangen Sammeln umfangreicher fachlicher Erfahrungen
					entschloss ich mich 2009 zur Selbstständigkeit und eröffnete mein
					Geschäft Groneberg Schwestern in der Osterstrasse.
					<br />
					<br />
					Das Handwerk liegt mir am Herzen!
					<br />
					<br />
					Als Expertin im Bereich Schnitt und Farbe ist mir die Zufriedenheit
					meiner Kundinnen und Kunden sehr wichtig. Neben dem Aufgreifen von
					aktuellen Trends und klassischen Stilen, die für mich beide die
					gleiche Wichtigkeit haben, liebe ich es mit dem Messer zu schneiden
					und fühle mich im Bereich der lockigen Struktur sehr zu Hause. Ich
					sehe in erster Linie den Menschen und lehne standardisierte Handgriffe
					ab.
					<br />
					<br />
					Ich bin jeden Tag dankbar für die Arbeit und den Austausch mit meinem
					Team.
					<br />
					<br />
					<br />
					<br />
					<hr class="w-64 border-t-2 mx-auto" />
				</div>
				<div>
					<img :src="require('@/assets/mitarbeiter/sina.jpg')" />
					Sina
					<br />
					<br />

					Im Jahr 2007 schloss ich erfolgreich meine Ausbildung in Lübeck ab.
					Aufgrund meiner Verbundenheit zur Stadt Hamburg zog es mich noch im
					selben Jahr dorthin. Seit 2009 gehöre ich fest zum Team der Groneberg
					Schwestern und war bei der Eröffnung dabei.
					<br />
					<br />

					Ich liebe den Umgang mit unseren Kunden und lebe mich gern kreativ im
					Bereich Farbe, Strähnen und Schnitt aus.
					<br />
					<br />
					<br />
					<br />
					<hr class="w-64 border-t-2 mx-auto" />
				</div>
				<div>
					<img :src="require('@/assets/mitarbeiter/antje.jpg')" />
					Antje
					<br />
					<br />
					Ick bin `ne Berliner Göre. Meine Ausbildung habe ich in Hannover mit
					16 Jahren begonnen. 2007 bin ich der Liebe wegen nach Hamburg gezogen.
					Seit 2010 hier im Salon, bin ich Friseurin aus Leidenschaft.
					Klassische Foliensträhnen sind meine Leidenschaft, außerdem
					Haarschnitte jeglicher Art und Föhnfrisuren.
					<br />
					<br />
					Mein Lebensmotto: Jede Minute, die du im Ärger verbringst, versäumst
					du 60 glückliche Sekunden.
					<br />
					<br />
					<br />
					<br />
					<hr class="w-64 border-t-2 mx-auto" />
				</div>
				<div>
					<img :src="require('@/assets/mitarbeiter/zora.jpg')" />
					Zora
					<br />
					<br />
					Mit 20 Jahren begann ich meine Ausbildung in Sindelfingen und lebte
					dort 7 Jahre. Ursprünglich komme ich aus dem schönen Wendland. 2008
					kam ich nach Hamburg. Ende 2013 bewarb ich mich hier im Salon.
					<br />
					<br />
					Ich widme mich mit viel Hingabe Farben und Paintings/ Balayage. 2012
					machte ich meinen Expert of Painting. Auch klassische Haarschnitte
					sind meine Leidenschaft.
					<br />
					<br />
					<br />
					<br />
					<hr class="w-64 border-t-2 mx-auto" />
				</div>
				<div>
					<img :src="require('@/assets/mitarbeiter/jasmin.jpg')" />
					Jasmin
					<br />
					<br />
					In Hamburg bin ich geboren und aufgewachsen. 2001 begann ich meine
					Ausbildung und bin seit 2004 Gesellin. Mein Start hier im Salon war
					2019. Im Bereich des Paintings erarbeite ich gern natürliche Töne .
					<br />
					<br />
					Generell mag ich Farben, Strähnen und Schnitte sehr gern. Locken
					schneide ich auch, kenne mich da aus, da ich selber Locken habe und
					weiss, wie man mit ihnen umgehen kann und was es für Möglichkeiten
					gibt.
					<br />
					<br />
					<br />
					<br />
					<hr class="w-64 border-t-2 mx-auto" />
				</div>
				<!--<div>
					<img :src="require('@/assets/mitarbeiter/bianca.jpg')" />
					Bianca
				</div>-->
				<!--<div><img :src="require('@/assets/mitarbeiter/greta.jpg')" />Greta</div>-->
				<div>
					<img :src="require('@/assets/mitarbeiter/imke.jpg')" />
					Imke
					<br />
					<br />
					Mit 19 Jahren begann ich meine Ausbildung 2017 in Hamburg. Gebürtig
					stamme ich aus dem Emsland. Meine Familie dort besuche ich regelmäßig.
					Bei den Groneberg Schwestern bin ich seit April 20.
					<br />
					<br />
					Mein Beruf ist für mich mehr als nur eine Tätigkeit; ich liebe ihn von
					ganzem Herzen. Das Föhnen ist meine Leidenschaft, und ich hege eine
					besondere Vorliebe für Foliensträhnen.
					<br />
					<br />
					<br />
					<br />
					<hr class="w-64 border-t-2 mx-auto" />
				</div>
				<div>
					<img :src="require('@/assets/mitarbeiter/jana.jpg')" />
					Jana
					<br />
					<br />
					Mit jugendlichen 15 Jahren startete ich meine Ausbildung zur
					Friseurin. Als waschechte Hamburgerin hege ich eine tiefe
					Verbundenheit zu meiner Heimatstadt und liebe sie von Herzen. Kurz
					nach meiner Ausbildung kam ich im Mai 2023 zu den Groneberg
					Schwestern.
					<br />
					<br />
					Ich fühle mich auf dem Gebiet der Farben, besonders Blondtöne, zu
					Hause und schneide gern klassische Schnitte. Trotz meiner frischen
					Qualifikation als Junggesellin bin ich immer noch dabei,
					kontinuierlich Neues aufzunehmen und meine Fertigkeiten
					weiterzuentwickeln.
					<br />
					<br />
					<br />
					<br />
					<hr class="w-64 border-t-2 mx-auto" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		metaInfo() {
			return {
				title:
					"Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Team",
				meta: [
					{
						name: "description",
						content:
							"Wir sind ein entspanntes, kreatives Team in ständiger Entwicklung.",
					},
					{
						property: "og:title",
						content:
							"Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Team",
					},
					{
						property: "og:site_name",
						content:
							"Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Team",
					},
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
	};
</script>

<style scoped>
	img {
		@apply w-full max-w-screen-sm mx-auto;
	}
</style>
