<template>
  <div>
    <div class="headline uppercase underline font-bold tracking-wider mb-8 text-xl">
        SALON | GALERIE
    </div>
    <div class="mitarbeiter">
      <div class="flex flex-wrap gap-4">
        <!--<div class="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-12">-->
        <div><img class="md:h-64" :src="require('@/assets/galerie/1.png')" /></div>
        <div><img class="md:h-64" :src="require('@/assets/galerie/2.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/3.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/4.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/5.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/6.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/7.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/8.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/9.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/10.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/11.png')" /></div> 
        <div><img class="md:h-64" :src="require('@/assets/galerie/12.png')" /></div> 
        </div>
    </div>
  </div>
</template>

<script>
export default {
metaInfo() {
        return { 
            title: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Salon & Galerie",
            meta: [
                { name: 'description', content:  'Finde hier ein paar Moods des Ladens und unseres Schaffens.'},
                { property: 'og:title', content: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Salon & Galerie"},
                { property: 'og:site_name', content: 'Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Salon & Galerie'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

<style>

</style>