<template>
  <div>
    <div class="headline uppercase underline font-bold tracking-wider mb-8 text-xl">
        ONLINE TERMINE BUCHEN
    </div>
    <div class="buchen">
      <span class="font-bold">Terminanfragen für Farbbehandlungen werden ausschließlich über das Telefon (040-38633581) angenommen.</span><br /><br />
      Euer Termin ist ausschließlich für euch gebucht.<br />
      Solltet ihr euren Termin nicht 24 Stunden vorher absagen, müssen wir leider 50% des Preises berechnen.
    </div>
    <a href="https://phorest.com/book/salons/gronebergschwestern" target="_blank">
      <div class="btn px-8 py-2 bg-white text-black inline-block mt-16">
        ONLINE TERMINE BUCHEN
      </div>
    </a>
  </div>
</template>

<script>
export default {
metaInfo() {
        return { 
            title: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Termine online buchen",
            meta: [
                { name: 'description', content:  'Buche bequem Deine Termine für Schnitte online auch außerhalb unserer Geschäftszeiten.'},
                { property: 'og:title', content: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Termine online buchen"},
                { property: 'og:site_name', content: 'Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Termine online buchen'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

<style>

</style>