<template>
  <div>
    <div class="headline uppercase underline font-bold tracking-wider mb-8 text-xl">
        JOBS
    </div>
    <div class="jobs">
       Neuer Job – neuer Spaß? Findest Du Deinen Beruf genau so toll wie wir? <br /><br />

Wir suchen <br />
<!--<div class="glitch-wrapper">
  <div class="glitch text-3xl font-bold tracking-wider" data-text="FRISEURE/-INNEN">FRISEURE/-INNEN</div>
</div>-->
<span class="text-3xl font-bold tracking-wider">FRISEURE/-INNEN</span>
<br /><br />mit Herz und Leidenschaft für Ihren Beruf in<br />
<span class="text-xl font-bold">Voll-/ oder Teilzeit.</span><br /><br />

Du bringst mit:<br /><br />

- gerne mindestens 1-2 Jahre Berufserfahrung<br />
- Teamfähigkeit<br />
- Interesse an neuen Trends<br /><br /><br />


Das bekommst Du bei uns:<br /><br />

- übertarifliche Bezahlung<br />
- flexible Arbeitszeiten<br />
- einen großen Kundenstamm<br />
- beste Lage in Eimsbüttel<br />
- feste Pausenzeiten dank Termingeschäft<br />
- Heiligabend und Silvester geschlossen.<br /><br />

Schick uns Deine Bewerbung per Post entweder direkt in den Laden in der Osterstr. 166, 20255 HH,<br />
ruf uns an unter 040-38 63 35 81 oder schicke uns einfach eine <a class="underline hover:bg-white hover:text-black" href="mailto:info@groneberg-schwestern.de">E-Mail</a>. Via Facebook und Instagram ginge natürlich auch. <br /><br />

Wir freuen uns auf Dich! :)
    </div>
  </div>
</template>

<script>
export default {
metaInfo() {
        return { 
            title: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Jobs",
            meta: [
                { name: 'description', content:  'Du möchtest Dich verändern? Bewirb Dich bei uns. Wir freuen uns auf Dich!'},
                { property: 'og:title', content: "Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Jobs"},
                { property: 'og:site_name', content: 'Groneberg Schwestern Friseur in Eimsbüttel - 040 38 63 35 81 - Jobs'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

<style scoped>
.glitch-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.glitch {
  color: white;
  text-transform: upercase;
  position: relative;
  display: inline-block;
}
.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
}
.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #49FC00;
  clip: rect(24px, 550px, 90px, 0);
  animation: glitch-anim-2 3s infinite linear alternate-reverse;
}
.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #b300fc;
  clip: rect(85px, 550px, 140px, 0);
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}
@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(90px, 9999px, 108px, 0);
  }
  4.166666666666666% {
    clip: rect(146px, 9999px, 135px, 0);
  }
  8.333333333333332% {
    clip: rect(128px, 9999px, 34px, 0);
  }
  12.5% {
    clip: rect(111px, 9999px, 100px, 0);
  }
  16.666666666666664% {
    clip: rect(2px, 9999px, 52px, 0);
  }
  20.833333333333336% {
    clip: rect(142px, 9999px, 39px, 0);
  }
  25% {
    clip: rect(63px, 9999px, 111px, 0);
  }
  29.166666666666668% {
    clip: rect(22px, 9999px, 96px, 0);
  }
  33.33333333333333% {
    clip: rect(115px, 9999px, 149px, 0);
  }
  37.5% {
    clip: rect(21px, 9999px, 81px, 0);
  }
  41.66666666666667% {
    clip: rect(27px, 9999px, 82px, 0);
  }
  45.83333333333333% {
    clip: rect(9px, 9999px, 77px, 0);
  }
  50% {
    clip: rect(32px, 9999px, 118px, 0);
  }
  54.166666666666664% {
    clip: rect(29px, 9999px, 145px, 0);
  }
  58.333333333333336% {
    clip: rect(93px, 9999px, 140px, 0);
  }
  62.5% {
    clip: rect(125px, 9999px, 114px, 0);
  }
  66.66666666666666% {
    clip: rect(64px, 9999px, 140px, 0);
  }
  70.83333333333334% {
    clip: rect(133px, 9999px, 139px, 0);
  }
  75% {
    clip: rect(104px, 9999px, 147px, 0);
  }
  79.16666666666666% {
    clip: rect(144px, 9999px, 12px, 0);
  }
  83.33333333333334% {
    clip: rect(23px, 9999px, 2px, 0);
  }
  87.5% {
    clip: rect(128px, 9999px, 127px, 0);
  }
  91.66666666666666% {
    clip: rect(62px, 9999px, 91px, 0);
  }
  95.83333333333334% {
    clip: rect(103px, 9999px, 76px, 0);
  }
  100% {
    clip: rect(116px, 9999px, 96px, 0);
  }
}
@keyframes glitch-anim {
  0% {
    clip: rect(90px, 9999px, 108px, 0);
  }
  4.166666666666666% {
    clip: rect(146px, 9999px, 135px, 0);
  }
  8.333333333333332% {
    clip: rect(128px, 9999px, 34px, 0);
  }
  12.5% {
    clip: rect(111px, 9999px, 100px, 0);
  }
  16.666666666666664% {
    clip: rect(2px, 9999px, 52px, 0);
  }
  20.833333333333336% {
    clip: rect(142px, 9999px, 39px, 0);
  }
  25% {
    clip: rect(63px, 9999px, 111px, 0);
  }
  29.166666666666668% {
    clip: rect(22px, 9999px, 96px, 0);
  }
  33.33333333333333% {
    clip: rect(115px, 9999px, 149px, 0);
  }
  37.5% {
    clip: rect(21px, 9999px, 81px, 0);
  }
  41.66666666666667% {
    clip: rect(27px, 9999px, 82px, 0);
  }
  45.83333333333333% {
    clip: rect(9px, 9999px, 77px, 0);
  }
  50% {
    clip: rect(32px, 9999px, 118px, 0);
  }
  54.166666666666664% {
    clip: rect(29px, 9999px, 145px, 0);
  }
  58.333333333333336% {
    clip: rect(93px, 9999px, 140px, 0);
  }
  62.5% {
    clip: rect(125px, 9999px, 114px, 0);
  }
  66.66666666666666% {
    clip: rect(64px, 9999px, 140px, 0);
  }
  70.83333333333334% {
    clip: rect(133px, 9999px, 139px, 0);
  }
  75% {
    clip: rect(104px, 9999px, 147px, 0);
  }
  79.16666666666666% {
    clip: rect(144px, 9999px, 12px, 0);
  }
  83.33333333333334% {
    clip: rect(23px, 9999px, 2px, 0);
  }
  87.5% {
    clip: rect(128px, 9999px, 127px, 0);
  }
  91.66666666666666% {
    clip: rect(62px, 9999px, 91px, 0);
  }
  95.83333333333334% {
    clip: rect(103px, 9999px, 76px, 0);
  }
  100% {
    clip: rect(116px, 9999px, 96px, 0);
  }
}
@-webkit-keyframes glitch-anim-2 {
  6.666666666666667% {
    clip: rect(117px, 9999px, 20px, 0);
  }
  10% {
    clip: rect(83px, 9999px, 146px, 0);
  }
  13.333333333333334% {
    clip: rect(96px, 9999px, 101px, 0);
  }
  16.666666666666664% {
    clip: rect(8px, 9999px, 13px, 0);
  }
  20% {
    clip: rect(89px, 9999px, 33px, 0);
  }
  23.333333333333332% {
    clip: rect(57px, 9999px, 118px, 0);
  }
  26.666666666666668% {
    clip: rect(80px, 9999px, 114px, 0);
  }
  30% {
    clip: rect(31px, 9999px, 67px, 0);
  }
  33.33333333333333% {
    clip: rect(40px, 9999px, 109px, 0);
  }
  36.666666666666664% {
    clip: rect(25px, 9999px, 99px, 0);
  }
  40% {
    clip: rect(94px, 9999px, 134px, 0);
  }
  43.333333333333336% {
    clip: rect(90px, 9999px, 66px, 0);
  }
  46.666666666666664% {
    clip: rect(60px, 9999px, 115px, 0);
  }
  50% {
    clip: rect(53px, 9999px, 116px, 0);
  }
  53.333333333333336% {
    clip: rect(125px, 9999px, 16px, 0);
  }
  56.666666666666664% {
    clip: rect(110px, 9999px, 21px, 0);
  }
  60% {
    clip: rect(54px, 9999px, 1px, 0);
  }
  63.33333333333333% {
    clip: rect(101px, 9999px, 127px, 0);
  }
  66.66666666666666% {
    clip: rect(24px, 9999px, 121px, 0);
  }
  70% {
    clip: rect(104px, 9999px, 123px, 0);
  }
  73.33333333333333% {
    clip: rect(35px, 9999px, 97px, 0);
  }
  76.66666666666667% {
    clip: rect(88px, 9999px, 114px, 0);
  }
  80% {
    clip: rect(63px, 9999px, 50px, 0);
  }
  83.33333333333334% {
    clip: rect(7px, 9999px, 29px, 0);
  }
  86.66666666666667% {
    clip: rect(45px, 9999px, 147px, 0);
  }
  90% {
    clip: rect(128px, 9999px, 63px, 0);
  }
  93.33333333333333% {
    clip: rect(13px, 9999px, 101px, 0);
  }
  96.66666666666667% {
    clip: rect(116px, 9999px, 88px, 0);
  }
  100% {
    clip: rect(24px, 9999px, 139px, 0);
  }
}
@keyframes glitch-anim-2 {
  6.666666666666667% {
    clip: rect(117px, 9999px, 20px, 0);
  }
  10% {
    clip: rect(83px, 9999px, 146px, 0);
  }
  13.333333333333334% {
    clip: rect(96px, 9999px, 101px, 0);
  }
  16.666666666666664% {
    clip: rect(8px, 9999px, 13px, 0);
  }
  20% {
    clip: rect(89px, 9999px, 33px, 0);
  }
  23.333333333333332% {
    clip: rect(57px, 9999px, 118px, 0);
  }
  26.666666666666668% {
    clip: rect(80px, 9999px, 114px, 0);
  }
  30% {
    clip: rect(31px, 9999px, 67px, 0);
  }
  33.33333333333333% {
    clip: rect(40px, 9999px, 109px, 0);
  }
  36.666666666666664% {
    clip: rect(25px, 9999px, 99px, 0);
  }
  40% {
    clip: rect(94px, 9999px, 134px, 0);
  }
  43.333333333333336% {
    clip: rect(90px, 9999px, 66px, 0);
  }
  46.666666666666664% {
    clip: rect(60px, 9999px, 115px, 0);
  }
  50% {
    clip: rect(53px, 9999px, 116px, 0);
  }
  53.333333333333336% {
    clip: rect(125px, 9999px, 16px, 0);
  }
  56.666666666666664% {
    clip: rect(110px, 9999px, 21px, 0);
  }
  60% {
    clip: rect(54px, 9999px, 1px, 0);
  }
  63.33333333333333% {
    clip: rect(101px, 9999px, 127px, 0);
  }
  66.66666666666666% {
    clip: rect(24px, 9999px, 121px, 0);
  }
  70% {
    clip: rect(104px, 9999px, 123px, 0);
  }
  73.33333333333333% {
    clip: rect(35px, 9999px, 97px, 0);
  }
  76.66666666666667% {
    clip: rect(88px, 9999px, 114px, 0);
  }
  80% {
    clip: rect(63px, 9999px, 50px, 0);
  }
  83.33333333333334% {
    clip: rect(7px, 9999px, 29px, 0);
  }
  86.66666666666667% {
    clip: rect(45px, 9999px, 147px, 0);
  }
  90% {
    clip: rect(128px, 9999px, 63px, 0);
  }
  93.33333333333333% {
    clip: rect(13px, 9999px, 101px, 0);
  }
  96.66666666666667% {
    clip: rect(116px, 9999px, 88px, 0);
  }
  100% {
    clip: rect(24px, 9999px, 139px, 0);
  }
}

</style>